<!--/*
 * @file           journal
 * @description    学会简介
 * @author         zrh
 * @date           2024-01-10
 */ -->
<template>
  <div class="container">
    <div class="main">
      <div class="TopBox">
        <div class="title">
          <!-- 学会简介 -->
          {{ $t("h.introduce") }}
        </div>
        <div class="topMain">
          {{ $t("h.introduceRow1") }}
          <br />
          <br />
          {{ $t("h.introduceRow2") }}
          <br />
          <br />
          {{ $t("h.introduceRow3") }}
          <br />
          <br />
          {{ $t("h.introduceRow4") }}
        </div>
      </div>

      <div class="title">
        {{ $t("h.presidentIntroduction") }}
      </div>
      <div class="positionItems">
        <div
          v-for="(item, index) in postMessage"
          :key="index"
          class="positionItem"
        >
          <div class="people">
            <div
              v-for="(person, index) in item.people"
              :key="index"
              style="display: flex"
              class="ItemBox"
            >
              <div class="leftBox">
                <div class="name">
                  {{ $t(`h.presidents[${index}].name`) }}
                </div>
                <div class="introduceBox">
                  <div class="introduce">
                    {{ $t(`h.presidents[${index}].introduce`) }}
                  </div>
                </div>
              </div>
              <div class="rightBox">
                <div class="avatar">
                  <img :src="person.pic" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="longtitle">{{ $t("h.committee") }}</div>
      <div class="CommingSoon"><img :src="commingPic" alt="" /></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      commingPic: require("../assets/images/comming.png"),
      postMessage: [
        {
          people: [
            {
              name: "何光沪",
              pic: require("../assets/images/firstPerson.png"),
              introduce:
                "原任中国社会科学院学术委员会委员、世界宗教研究所研究员、宗教学原理研究室主任，中国人民大学校务委员会委员、学位委员会委员、宗教学教授；著有《生于忧患、长于忧患：何光沪汉语神学文选》《Sino-Christian Theology》《秉烛隧中》 《百川归海：走向全球宗教哲学》《月映万川：宗教、社会与人生》《多元化的上帝观：20世纪西方宗教哲学概览》等。",
            },
            {
              name: "罗秉祥",
              pic: require("../assets/images/FourthPerson.png"),
              introduce:
                "美国富勒神学院斯特芬伉俪中华研究讲席教授，中华研究中心学术主任。曾在香港浸会大学宗教及哲学系任教31年，及在洛杉矶正道神学院担任驻院学者及资深伦理学教授。在美国获得两个博士学位：耶鲁大学（1990，基督教伦理学），纽约州立大学水牛城分校（1982，西方道德哲学）。著有《黑白分明：基督教伦理学纵横谈》《自由社会的道德底线》《公理婆理话伦理》《同性恋的十字架：伦理学者的对话与交锋》。",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
  },
  methods: {},
};
</script>
<style lang="less" scoped>
// PC端
@media (min-width: 768px) {
  .CommingSoon {
    height: 400px;
    text-align: left;

    img {
      margin-left: 100px;
      width: auto;
      height: 100%;
    }
  }
  .container {
    width: 99vw;
    display: flex;
    justify-content: center;
    .main {
      background-color: #f5f5f5;
      width: 85%;
      display: flex; // 父级的flex 代表子级元素的显示由原来默认的block 变为 inline-block
      flex-direction: column;
      padding-top: 20px;
      .TopBox {
        text-align: left;
        .topMain {
          font-family: "KaiTi", serif;
          margin: 80px 150px;
          font-size: 30px;
        }
      }
      .title {
        font-family: "KaiTi", serif;
        position: relative; //
        margin-left: 80px;
        font-size: 40px;
        font-weight: 600;
        text-align: left;
      }
      .title::after {
        position: absolute;
        content: "";
        width: 160px;
        height: 10px;
        left: 0;
        top: 50px;
        background-color: #1c5aa0;
      }
      .longtitle {
        font-family: "KaiTi", serif;
        position: relative; //
        margin-left: 80px;
        font-size: 40px;
        font-weight: 600;
        text-align: left;
      }
      .longtitle::after {
        position: absolute;
        content: "";
        width: 200px;
        height: 10px;
        left: 0;
        top: 50px;
        background-color: #1c5aa0;
      }
      .positionItems {
        margin-top: 40px;
        font-family: "KaiTi", serif;
        padding-left: 100px;
        .positionItem {
          .position {
            font-size: 40px;
            text-align: left;
            font-weight: 600;
          }
          .people {
            text-align: left;
            margin-top: 70px;
            margin-left: 50px;
            font-size: 50px;
          }
          .leftBox {
            width: 55%;
            .name {
              font-size: 50px;
              margin-bottom: 30px;
              color: #929292;
            }
            .introduceBox {
              font-size: 16px;
              display: flex;
              justify-content: space-between;
              padding-right: 50px;
              margin-bottom: 130px;
              .introduce {
                font-size: 30px;
              }
            }
          }
          .rightBox {
            margin-right: 150px;
            margin-left: 200px;
            img {
              width: 300px;
              height: 300px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  img {
    width: 150px;
    height: 150px;
  }
  .container {
    padding: 0 20px;
    .main {
      justify-content: center;
      align-items: center;
      width: 100%;
      display: flex; // 父级的flex 代表子级元素的显示由原来默认的block 变为 inline-block
      flex-direction: column;
      padding-top: 20px;
      .topMain {
        text-align: left;
        margin-bottom: 30px;
        padding: 0 20px;
      }
      .title {
        position: relative; //
        // margin-left: 50px;
        font-size: 30px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 30px;
        width: 100%;
      }
      .title::after {
        position: absolute;
        content: "";
        width: 120px;
        height: 10px;
        left: 0;
        top: 40px;
        background-color: #1c5aa0;
      }
      .longtitle {
        width: 100%;
        font-family: "KaiTi", serif;
        position: relative; //
        // margin-left: 80px;
        font-size: 30px;
        font-weight: 600;
        text-align: left;
      }
      .longtitle::after {
        position: absolute;
        content: "";
        width: 150px;
        height: 10px;
        left: 0;
        top: 40px;
        background-color: #1c5aa0;
      }
      .positionItems {
        .position {
          font-size: 30px;
          text-align: center;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .people {
          display: flex;
          flex-direction: column;
          .ItemBox {
            display: flex;
            flex-direction: column-reverse;
            .leftBox {
              .name {
                margin-top: 20px;
                font-size: 30px;
                margin-bottom: 20px;
                color: #929292;
              }
              .introduceBox {
                padding: 0 20px;
                text-align: left;
                .introduce {
                  margin-bottom: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
