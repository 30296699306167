<!--
 * @description: 
 * @param: 
 * @return: 
 * @Author: zrh
 * @Date: 2024-01-12 21:44:54
 * @LastEditors: sueRimn
 * @LastEditTime: 2024-02-24 16:24:22
-->
<!--
 * @description: 首页上部导航栏
 * @param: 
 * @return: 
 * @Author: zrh
 * @Date: 2024-01-01 09:34:06
 * @LastEditors: sueRimn
 * @LastEditTime: 2024-02-06 18:41:54
-->
<template>
  <div id="app">
    <div id="nav">
      <div class="logo" @click="pushTo('/home')">
        <img src="./assets/images/logo.png" alt="" />
      </div>
      <!-- 名称 -->
      <div class="title" @click="pushTo('/home')">
        <!-- 汉语公共神学学会 -->
        {{ $t("h.homeTitle") }}
        <span
          class="mobileHidden englishFont"
          style="font-size: 16px; letter-spacing: 0"
        >
          <br />
          Society for Chinese Public Theology
        </span>
      </div>
      <div class="menus">
        <div class="menu" @click="pushTo('/latestMessage')">
          <!-- 最新消息 -->
          {{ $t("h.latestMessage") }}
          <span
            class="mobileHidden englishFont"
            style="font-size: 14px; height: auto"
          >
            <br />
            Latest News
          </span>
        </div>
        <div class="menu" @click="showSubMenu(1)">
          <!-- 研究出版 -->
          {{ $t("h.learnPublish") }}
          <span
            class="mobileHidden englishFont"
            style="font-size: 14px; height: auto"
          >
            <br />
            Research & Publication
          </span>
          <ul class="sub-menu" v-show="flag[1]" @mouseleave="handleMouseOut(1)">
            <li @click="pushTo('/journal')">
              <div>
                <!-- 学术期刊 -->
                {{ $t("h.journal") }}
              </div>
            </li>
            <li @click="pushTo('/book')">
              <div>{{ $t("h.book") }}</div>
            </li>
            <li @click="pushTo('/article')">
              <div>{{ $t("h.project") }}</div>
            </li>
          </ul>
        </div>
        <div class="menu" @click="showSubMenu(2)">
          <!-- 会议讲座 -->
          {{ $t("h.meeting") }}
          <span
            class="mobileHidden englishFont"
            style="font-size: 14px; height: auto"
          >
            <br />
            Conference & Lecture
          </span>
          <ul class="sub-menu" v-show="flag[2]" @mouseleave="handleMouseOut(2)">
            <li @click="pushTo('/meeting')">
              <div>
                <!-- 年度会议 -->
                {{ $t("h.annualMeeting") }}
              </div>
            </li>
            <li @click="pushTo('/lecture')">
              <div>{{ $t("h.specialSpeech") }}</div>
            </li>
          </ul>
        </div>
        <div class="menu" @click="showSubMenu(3)">
          <!-- 关于我们 -->
          {{ $t("h.about") }}
          <span
            class="mobileHidden englishFont"
            style="font-size: 14px; height: auto"
          >
            <br />
            About Us
          </span>
          <ul class="sub-menu" v-show="flag[3]" @mouseleave="handleMouseOut(3)">
            <li>
              <div @click="pushTo('/introductionToTheSociety')">
                {{ $t("h.introduce") }}
              </div>
            </li>
            <li @click="pushTo('/editorialBoard')">
              <div>{{ $t("h.member") }}</div>
            </li>
            <li @click="pushTo('/contactSupport')">
              <div>{{ $t("h.contactSupport") }}</div>
            </li>
          </ul>
        </div>

        <div class="menu PcHidden" @click="showSubMenu(4)">
          <i class="el-icon-user"></i>
          <span v-if="!isLogin">
            <!-- 登录  -->
            {{ $t("h.login") }}
          </span>
          <span v-else class="usernameInMobile"> {{ username }}</span>
          <ul class="sub-menu" v-show="flag[4]" @mouseleave="handleMouseOut(4)">
            <li v-if="!isLogin">
              <div @click="pushTo('/memberLogin')">
                {{ $t("h.userLogin") }}
              </div>
            </li>
            <li v-if="!isLogin">
              <div @click="pushTo('/authorLogin')">
                <!-- 作者登录 -->
                {{ $t("h.authorLogin") }}
              </div>
            </li>
            <li v-if="isLogin">
              <div @click="pushTo('/userCenter')">
                <!-- 用户中心 -->
                {{ $t("h.userCenter") }}
              </div>
            </li>
            <li v-if="isLogin">
              <div @click="logout()">
                {{ $t("h.logOut") }}
              </div>
            </li>
          </ul>
        </div>
        <div
          class="menu PcHidden"
          style="display: flex; justify-content: center"
        >
          <div class="translateLanguageMode" @click="toggleLanguageMode">
            <span :style="getStyle(1)" v-if="languageMode === 1">
              <!-- 简体 -->
              <img src="./assets/images/languageOne.png" alt="" />
            </span>
            <span :style="getStyle(0)" v-else>
              <!-- 繁体 -->
              <img src="./assets/images/LanguageTwo.png" alt="" />
            </span>
          </div>
        </div>
      </div>
      <div class="search PcHidden">
        <el-input :placeholder="inputDesc1" v-model="input" class="searchInput">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchForDetail"
          ></el-button>
        </el-input>
      </div>
      <div class="search mobileHidden">
        <el-input :placeholder="inputDesc1" v-model="input" class="searchInput">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="searchForDetail"
          ></el-button>
        </el-input>
      </div>
      <div class="menus mobileHidden">
        <div class="menu" @click="showSubMenu(4)">
          <i class="el-icon-user"></i>
          <span v-if="!isLogin">
            <!-- 登录  -->
            {{ $t("h.login") }}
          </span>
          <span v-else class="usernameInMobile"> {{ username }}</span>
          <ul class="sub-menu" v-show="flag[4]" @mouseleave="handleMouseOut(4)">
            <li v-if="!isLogin">
              <div @click="pushTo('/memberLogin')">
                <!-- 用户登录 -->
                {{ $t("h.userLogin") }}
              </div>
            </li>
            <li v-if="!isLogin">
              <div @click="pushTo('/authorLogin')">
                <!-- 作者登录 -->
                {{ $t("h.authorLogin") }}
              </div>
            </li>
            <li v-if="isLogin">
              <div @click="pushTo('/userCenter')">
                <!-- 用户中心 -->
                {{ $t("h.userCenter") }}
              </div>
            </li>
            <li v-if="isLogin">
              <div @click="logout()">
                <!-- 退出登录 -->
                {{ $t("h.logOut") }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="translateLanguageMode mobileHidden"
        @click="toggleLanguageMode"
      >
        <span :style="getStyle(1)" v-if="languageMode === 1">
          <!-- 简体 -->
          <img src="./assets/images/languageOne.png" alt="" />
        </span>
        <span :style="getStyle(0)" v-else>
          <!-- 繁体 -->
          <img src="./assets/images/LanguageTwo.png" alt="" />
        </span>
      </div>
    </div>
    <router-view @alreadyLogin="alreadyLogin" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      language: localStorage.getItem("lang") || "zh", //
      languageMode: 1, //表示简繁体  简体表示1 繁体表示0 默认简体
      username: "",
      isLogin: false,
      input: "",
      flag: [false, false, false, false, false],
    };
  },
  created() {},
  mounted() {
    this.judgeLogin();
    this.languageMode = this.language === "zh" ? 0 : 1;
  },
  computed: {
    inputDesc1() {
      return this.$t("h.search");
    },
  },
  methods: {
    toggleLanguageMode() {
      // 当前为简体
      if (this.language == "zh") {
        localStorage.setItem("locale", "tc");
        this.$i18n.locale = localStorage.getItem("locale");
        this.$message({
          message: "切换为繁体",
          type: "success",
        });
        localStorage.setItem("lang", "tc");
        this.language = "tc";
        location.reload(); //重新加载
      } else if (this.language == "tc") {
        localStorage.setItem("locale", "zh");
        this.$i18n.locale = localStorage.getItem("locale");
        this.$message({
          message: "切换为简体!",
          type: "success",
        });
        localStorage.setItem("lang", "zh");
        this.language = "zh";
        location.reload();
      }
      // 切换 languageMode 的值
      this.languageMode = this.language == "zh" ? 0 : 1;
    },
    getStyle(mode) {
      // 根据 mode 返回动态样式
      return mode === 1
        ? { color: "blue", fontWeight: "bold" } // 简体样式
        : { color: "green", fontStyle: "italic" }; // 繁体样式
    },
    async judgeLogin() {
      const res = await this.$http.get("/user/profile/get");
      if (res.data.code == 401) {
        this.$message({
          message: "登录信息已过期或未登录,请重新登录",
          type: "error",
        });
        localStorage.removeItem("user");
        localStorage.removeItem("userInfo");
        this.isLogin = false;
        return;
      }
      this.username = JSON.parse(localStorage.getItem("userInfo"))?.nickName;
      console.log("username", this.username);
    },
    // 子元素触发  已登录    用户登录与作者登录完成后 更新username 来显示
    async alreadyLogin(nickName) {
      this.isLogin = JSON.parse(localStorage.getItem("user")).isLogin || false;
      if (JSON.parse(localStorage.getItem("userInfo")))
        this.username = JSON.parse(localStorage.getItem("userInfo"))?.nickName;
    },
    // 两种登录
    pushToLogin(path, type) {
      this.$router.push({ path: `${path}`, query: { type } });
      this.$forceUpdate();
    },
    // 点击搜索按钮
    searchForDetail() {
      this.$router.push({
        path: "/searchDetail",
        query: { keyword: this.input, mode: "or" },
      });
      this.input = "";
    },
    // 跳转指定路径
    pushTo(path) {
      console.log("path", path);
      this.flag = [false, false, false, false, false];
      this.$router.push(`${path}`);
    },
    // 退出登录
    async logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("userInfo");
      const { data: res } = await this.$http.get("/logout");
      if (res.code == "SUCCESS") {
        this.$message.success(res.message);
        this.pushTo("/memberLogin");
        this.isLogin = false;
      } else {
        this.$message.warning(res.message);
      }
    },
    // 鼠标移出将本次
    handleMouseOut(index) {
      console.log("index", index);
      this.$set(this.flag, index, false);
    },
    //数组控制二级导航栏的显示与隐藏
    showSubMenu(index) {
      console.log(index);
      console.log(this.flag[index]);
      this.$set(this.flag, index, !this.flag[index]);
    },
  },
};
</script>
<style lang="less">
// PC端
@media (min-width: 768px) {
  .translateLanguageMode {
    margin-right: 2vw;
    text-align: center;
    width: 25px;
    height: 25px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .PcHidden {
    display: none !important;
  }
  .englishFont {
    font-family: "Times New Roman", Times, serif;
  }
  /* 在这里添加PC端的样式 */
  * {
    padding: 0;
    margin: 0;
  }
  #app {
    font-family: KaiTi;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  #nav {
    // overflow: hidden;
    position: relative;
    display: flex;
    padding-left: 60px;
    align-items: center;
    background-color: #404559;
    height: 83px;
    color: #fff;
    cursor: pointer;
    .logo {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-right: 20px;
      img {
        width: 70px;
        height: 70px;
      }
    }
    .title {
      margin-right: 40px;
      letter-spacing: 5px;
      font-size: 26px;
      text-align: left;
    }
    .search {
      margin-right: 1vw;
      flex: 1;
      display: flex;
      flex-direction: row-reverse;
    }
    .menus {
      display: flex;
      align-items: center;
      .menu {
        z-index: 1000;
        position: relative;
        height: 100%;
        width: 160px;
        font-size: 18px;
        border-right: 1px solid #fff;
      }

      .sub-menu {
        position: absolute;
        width: 150px;
        margin-top: 10px;
        list-style: none;
        background-color: #1c5a9b;
        color: #fff;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          background-color: #35384b;
          border-bottom: 2px solid #405a85;
        }
        li:hover {
          background-color: #1b5b9e;
        }
      }
      .menu:last-child {
        border-right: none;
      }
    }
    .searchInput {
      margin-left: 5vw;
      text-align: center;
      height: 40px;
      width: 15vw;
      border-radius: 10px;
    }
  }
}
// 手机端
@media (max-width: 767px) {
  .translateLanguageMode {
    margin-right: 2vw;
    text-align: center;
    width: 25px;
    height: 25px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .mobileHidden {
    display: none !important;
  }
  .usernameInMobile {
  }
  /* 在这里添加手机端的样式 */
  * {
    padding: 0;
    margin: 0;
  }
  #app {
    font-family: KaiTi;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  #nav {
    position: relative;
    display: flex;
    padding-left: 5vw;
    padding-top: 3vh;
    flex-wrap: wrap;
    align-items: center;
    background-color: #404559;
    height: 28vh;
    color: #fff;
    cursor: pointer;
    .logo {
      width: 18vw;
      height: 18vw;
      border-radius: 50%;
      background-color: #efefef;
      margin-right: 3vw;
      margin-left: 5vw;
      margin-bottom: 5vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      width: auto;
      margin-right: 40px;
      letter-spacing: 2px;
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 5vw;
    }
    .search {
      // margin-right: 1vw;
      flex: 1;
      width: 12vw;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: -2vw;
      margin-bottom: 3vw;
      margin-left: 5vw;
      // flex-direction: row-reverse;
    }
    .menus {
      display: flex;
      flex-wrap: wrap;
      .menu {
        position: relative;
        height: 5vh;
        width: 27vw;
        font-size: 18px;
      }

      .sub-menu {
        z-index: 1000;
        position: absolute;
        width: 27vw;
        list-style: none;
        background-color: #1c5a9b;
        color: #fff;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          background-color: #35384b;
          border-bottom: 2px solid #405a85;
        }
        li:hover {
          background-color: #1b5b9e;
        }
      }
      .menu:last-child {
        border-right: none;
      }
    }
    .el-input__inner {
      // border-radius: 10px;
      height: 3vh;
    }
    .searchInput {
      text-align: center;
      height: 3vh;
      width: 42vw;
      // border-radius: 10px;
    }
    .el-button {
      width: 5vw;
    }
    .el-input-group__append,
    .el-input-group__prepend {
      background-color: #fff;
    }
    .el-input-group > .el-input__inner {
      border: none;
    }
    .el-input-group__append,
    .el-input-group__prepend {
      border: none;
      outline: none;
      padding-left: 0;
      padding-right: 20px;
    }
    .el-input-group__append {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border: none;
    }
  }
}
</style>
