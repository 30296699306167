// 繁体
export const h = {
    homeTitle:"漢語公共神學學會",
    latestMessage:"最新消息",
    learnPublish:"研究出版",
    meeting:"會議講座",
    journal:"學術期刊",
    about:"關於我們",
    login:"登錄",
    introduce:"學會簡介",
    member:"理事成員",
    book:"秉燭叢書",
    project:"專項課題",
    annualMeeting:"年度會議",
    specialSpeech:"專題講座",
    contactSupport:"聯系支持",
    userLogin:"用戶登錄",
    authorLogin:"作者登錄",
    userCenter:"用戶中心",
    logOut:"退出登錄",
    knowMore:"了解更多",
    supportDesc:"想要更多了解或支持學會及其事工和項目，請致信 info@sfcpt.org",
    journalIntroduction:"期刊簡介",
    latestJournal:"最新期刊",
    recommandRead:"推薦閱讀",
    latestMeeting:"最新會議",
    latestSpeech:"最新講座",
    userLoginAndRegister:"作者登錄與註冊",
 
    register:"註冊",
    introduceRow1:"漢語公共神學學會（SOCIETY FOR CHINESE PUBLIC THEOLOGY）的使命是:發展本於聖經、普世且處境化的漢語公共神學體系，促進華人教會對公共事務的理解和健康參與，增進社會的整體福祉。",
    introduceRow2:"本會設立學術委員會與教會發展委員會，致力於公共神學及相關議題的學術研究，關註且推動公共神學在教會牧養、神學教育及宣教現場的開展與實踐；通過出版期刊、叢書，組織課題研究，舉辦講座和會議等形式持續深入推動漢語公共神學的建設和發展。",
    introduceRow3:"本會期刊《秉燭：漢語公共神學國際學刊》（INTERNATIONAL JOURNAL OFCHINESE PUBLIC THEOLOGY）創刊號於2024年12月出版。",
    introduceRow4:"本會於2024年7月22-26日，在美國密西根州的加爾文神學院舉行主辦了第壹屆漢語公共神學會議及成立會議，並推舉何光滬、羅秉祥擔任會長與副會長。",
    presidentIntroduction:"會長簡介",
    contactSupportRow1:"漢語公共神學學會目前正在美國註冊登記成為非營利機構，",
    contactSupportRow2:"並期望可以取得501(c)(3)免稅資格。(c)(3)免税资格。",
    contactSupportRow3:"想要更多了解或支持學會及其事工和項目，請致信",
    committee:"學術委員會",
    userLoginTitle:"用戶登錄與註冊",
    inputEmail:"請輸入郵箱賬號",
    inputPassword:"請輸入密碼",
    validateCode:"驗證碼",
    presidents:[
        {
        name:"何光滬",
        introduce:"原任中國社會科學院學術委員會委員、世界宗教研究所研究員、宗教學原理研究室主任，中國人民大學校務委員會委員、學位委員會委員、宗教學教授；著有《生於憂患、長於憂患：何光滬漢語神學文選》《Sino-Christian Theology》《秉燭隧中》 《百川歸海：走向全球宗教哲學》《月映萬川：宗教、社會與人生》《多元化的上帝觀：20世紀西方宗教哲學概覽》等。",
    },
    {
        name:"羅秉祥",
        introduce:"美國富勒神學院斯特芬伉儷中華研究講席教授，中華研究中心學術主任。曾在香港浸會大學宗教及哲學系任教31年，及在洛杉磯正道神學院擔任駐院學者及資深倫理學教授。在美國獲得兩個博士學位：耶魯大學（1990，基督教倫理學），紐約州立大學水牛城分校（1982，西方道德哲學）。著有《黑白分明：基督教倫理學縱橫談》《自由社會的道德底線》《公理婆理話倫理》《同性戀的十字架：倫理學者的對話與交鋒》。",
    }],
    forgetPassword:"忘記密碼",
    search:"請輸入內容",
    searchResult:"搜索結果",

}   