<!--/*
 * @file           Journal
 * @description    期刊页面
 * @author         zrh
 * @date            2024-01-10
 */ -->
<template>
  <div class="container">
    <div class="swiper">
      <img src="../assets/images/journal.jpg" alt="" />
    </div>
    <div class="part" @click="pushIntoDetail(firstJournal.id)">
      <div class="titlePart">
        <div class="title">
          {{ $t("h.journalIntroduction") }}
        </div>
        <div class="line"></div>
      </div>
      <!-- 期刊号:期刊名 -->
      <div class="description">
        {{ firstJournal.issn }}:{{ firstJournal.name }}
      </div>
      <div class="partBox">
        <div class="pic">
          <img :src="firstJournal.imgSrc" alt="" />
        </div>
        <div class="content">{{ firstJournal.summary }}</div>
      </div>
      <div class="publish">
        PUBLISHED: {{ firstJournal.publishTime?.substring(0, 7) || "" }}
      </div>
      <!-- <div class="contentLabel">{{ firstJournal.tagKeywords }}</div> -->
      <div class="contentLabels">
        <div
          class="contentLabel"
          v-for="(keyword, index) in firstLatestKeywordList"
          :key="index"
          @click="pushIntoSearchDetail(keyword)"
        >
          {{ keyword }}
        </div>
      </div>
      <!--  PicBox -->
      <div
        class="picBox"
        v-if="
          firstJournal.resourceType == '1' || firstJournal.resourceType == '2'
        "
      >
        <img
          src="../assets/images/pdfWay.png"
          alt=""
          v-if="firstJournal.resourceType == '1'"
          @click.stop="download(firstJournal)"
        />
        <img
          src="../assets/images/otherWay.png"
          alt=""
          v-if="firstJournal.resourceType == '2'"
          @click.stop="downloadResourceUrl(firstJournal.resourceUrl)"
        />
      </div>
    </div>

    <div class="part">
      <div class="titlePart">
        <div class="title">最新期刊</div>
        <div class="line"></div>
      </div>
    </div>
    <div
      class="part"
      v-for="(item, index) in journalList"
      :key="index"
      @click="pushIntoDetail(item.id)"
    >
      <!-- 期刊号:期刊名 -->
      <div class="description" style="margin-top: 0">
        {{ item.issn }}:{{ item.name }}
      </div>
      <div class="partBox">
        <div class="pic">
          <img :src="item.imgSrc" alt="" />
        </div>
        <div class="content">{{ item.summary }}</div>
      </div>
      <div class="publish">
        PUBLISHED: {{ item.publishTime?.substring(0, 7) || "" }}
      </div>
      <div class="contentLabels">
        <div
          class="contentLabel"
          v-for="(keyword, index) in item.latestKeywordList"
          :key="index"
          @click="pushIntoSearchDetail(keyword)"
        >
          {{ keyword }}
        </div>
      </div>
      <!--  PicBox -->
      <div
        class="picBox"
        v-if="item.resourceType == '1' || item.resourceType == '2'"
      >
        <img
          src="../assets/images/pdfWay.png"
          alt=""
          v-if="item.resourceType == '1'"
          @click.stop="download(item)"
        />
        <img
          src="../assets/images/otherWay.png"
          alt=""
          v-if="item.resourceType == '2'"
          @click.stop="downloadResourceUrl(item.resourceUrl)"
        />
      </div>
    </div>
    <el-pagination
      style="margin: 20px 0"
      background
      layout="prev, pager, next"
      :total="totalCount"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      totalCount: 0,
      journalList: [],
      firstJournal: {},
      firstLatestKeywordList: [],
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      pageForFix: {
        //专门用于
        pageIndex: 1,
        pageSize: 10,
        name: "期刊简介",
      },
    };
  },
  mounted() {
    // 需求 固定第一个为指定姓名 后面为按顺序排列 但是 第一个制定姓名的元素  不会在最新期刊列表中出现
    this.getFixJournalMessage();
    this.getJournalMessage();
  },
  methods: {
    handleCurrentChange(val) {
      this.page.pageIndex = val;
      this.getJournalMessage();
    },
    // 路径跳转使用
    pushTo(path) {
      console.log("path", path);
      this.flag = [false, false, false, false, false];
      this.$router.push(`${path}`);
    },

    //  在下载前判断
    async validateUserLogin() {
      const res = await this.$http.get("/user/profile/get");
      if (res.data.code == 401) {
        this.$message({
          message: "登录信息已过期或未登录,请重新登录",
          type: "error",
        });
        return false;
      } else {
        return true;
      }
    },
    //  pdf 下载
    async download(item) {
      let id = item.resourceUrl.match(/\d+/g).join(""); //提取resourceUrl中的数字部分
      // 用户未登录或由于浏览器索引导致残留的残留登录 需要重置一下
      if (!(await this.validateUserLogin())) {
        return;
      }
      const res = await this.$http.get(
        "/common/downloadByTypeAndId",
        {
          type: "PDF",
          id,
        },
        { responseType: "blob" }
      );
      if (res.headers["content-type"] == "application/json;charset=utf-8") {
        // 当后台返回一个json时候 就说明 是没有权限的401情况 此时要两次拦截
        //  下载后再次进行一次校验
        this.$message({
          message: "登录信息已过期或未登录,请重新登录",
          type: "error",
        });
        return;
      }
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "下载文件.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    // 根据资源链接下载
    downloadResourceUrl(url) {
      window.open(url, "_blank");
    },
    // 点击按钮进入搜索界面
    pushIntoSearchDetail(keyword) {
      this.$router.push({
        path: "/searchDetail",
        query: { keyword, mode: "and" },
      });
    },
    async getFixJournalMessage() {
      const { data: res } = await this.$http.get(
        "/busMagazine/list",
        this.pageForFix
      );
      let newData = res.data.list; //只会有一个元素
      newData.forEach((item, index) => {
        const frontendIP = window.location.hostname;
        // 获取前端的端口地址
        let frontendPort = window.location.port;
        if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
        item.imgSrc =
          "http://" +
          frontendIP +
          ":" +
          frontendPort +
          process.env.VUE_APP_BASE_API +
          item.coverImage;
        this.firstJournal = item;
        if (this.firstJournal.tagKeywords)
          this.firstLatestKeywordList =
            this.firstJournal.tagKeywords.split(" ");
      });
    },
    async getJournalMessage() {
      this.journalList = [];
      const { data: res } = await this.$http.get(
        "/busMagazine/list",
        this.page
      );
      let newData = res.data.list;
      this.totalCount = res.data.totalCount;
      // console.log("total", this.totalCount);
      newData.forEach((item, index) => {
        if (item.id != this.firstJournal.id) {
          const frontendIP = window.location.hostname;
          // 获取前端的端口地址
          let frontendPort = window.location.port;
          if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
          item.imgSrc =
            "http://" +
            frontendIP +
            ":" +
            frontendPort +
            process.env.VUE_APP_BASE_API +
            item.coverImage;
          if (item.tagKeywords)
            item.latestKeywordList = item.tagKeywords.split(" ");
          this.journalList.push(item);
          // console.log("img", this.journalList);
        }
      });
    },
    pushIntoDetail(id) {
      this.$router.push({
        path: "/messageDetail",
        query: { id, type: "journal" },
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-pager li.active {
  background-color: #484e66 !important;
}
@media (min-width: 768px) {
  .container {
    .swiper {
      background-color: #f5f5f5;
      width: 100%;
      height: 500px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .part {
      text-align: left;
      padding: 30px;
      padding-left: 50px;
      margin: 0 10vw 0 10vw;
      cursor: pointer;

      .titlePart {
        display: flex;
        align-items: center;

        .title {
          width: auto;
          font-weight: 600;
          font-size: 36px;
          color: #000;
        }

        .line {
          margin-left: 20px;
          flex-grow: 1;
          height: 4px;
          background-color: #d2d2d2;
        }
      }

      .description {
        margin-top: 50px;
        color: #585858;
        font-weight: 600;
        font-size: 18px;
      }

      .partBox {
        margin-top: 20px;
        display: flex;

        .pic {
          width: 350px;
          height: 200px;
          background-color: #f5f5f5;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .content {
          margin-left: 40px;
          width: calc(65% - 40);
          height: 200px;
          color: #626262;
        }
      }

      .publish {
        margin-top: 30px;
        color: #707070;
        text-align: left;
        font-weight: 600;
        font-size: 18px;
      }

      .contentLabels {
        display: flex;

        .contentLabel {
          margin: 20px 10px 0 0;
          font-size: 18px;
          font-weight: 600;
          color: #3d728b;
        }

        .contentLabel:hover {
          color: red;
        }
      }

      .btn {
        margin-top: 30px;
        font-weight: 600;
        font-size: 16px;
        background-color: #54a966;
      }

      .picBox {
        margin-top: 30px;
        width: 300px;
        height: 50px;
        display: flex;
        img {
          cursor: pointer;
          margin-right: 30px;
          width: 50%;
          height: 100%;
          // flex: 1;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .container {
    .swiper {
      background-color: #f5f5f5;
      width: 100%;
      height: 300px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .part:nth-child(3) {
      box-shadow: none !important;
    }
    .part {
      text-align: left;
      margin: 0 5vw 5vh 5vw;
      cursor: pointer;
      box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.3);
      .titlePart {
        display: flex;
        align-items: center;
        .title {
          width: auto;
          font-weight: 600;
          font-size: 36px;
          color: #000;
        }

        .line {
          margin-left: 20px;
          flex-grow: 1;
          height: 4px;
          background-color: #d2d2d2;
        }
      }

      .description {
        padding: 0 5vw;
        margin-top: 50px;
        color: #000;
        font-weight: 600;
        font-size: 18px;
      }

      .partBox {
        padding: 0 5vw;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        .pic {
          width: 100%;
          height: 160px;
          background-color: #f5f5f5;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .content {
          margin: 20px 0;
          width: calc(65% - 40);
          height: auto;
          color: #626262;
        }
      }

      .publish {
        padding: 0 5vw;
        margin-top: 10px;
        color: #707070;
        text-align: left;
        font-weight: 600;
        font-size: 18px;
      }

      .contentLabels {
        display: flex;
        // padding: 0 5vw;
        .contentLabel {
          padding: 0 5vw;
          margin: 20px 10px 15px 0;
          font-size: 18px;
          font-weight: 600;
          color: #3d728b;
        }

        .contentLabel:hover {
          color: red;
        }
      }

      .btn {
        margin-top: 30px;
        font-weight: 600;
        font-size: 16px;
        background-color: #54a966;
      }

      .picBox {
        margin-top: 20px;
        padding-bottom: 20px;
        margin-left: 3vh;
        width: 55vw;
        height: 50px;
        display: flex;

        img {
          cursor: pointer;
          margin-right: 30px;
          width: 50%;
          height: 100%;
          // flex: 1;
        }
      }
    }
  }
}
</style>
