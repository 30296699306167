// 简体
export const h = {
    homeTitle:"汉语公共神学学会",
    latestMessage:"最新消息",
    learnPublish:"研究出版",
    journal:"学术期刊",
    meeting:"会议讲座",
    latestMeeting:"最新会议",
    about:"关于我们",
    login:"登录",
    register:"注册",
    userLoginAndRegister:"作者登录与注册",
    introduce:"学会简介",
    member:"理事成员",
    book:"秉烛丛书",
    project:"专项课题",
    annualMeeting:"年度会议",
    specialSpeech:"专题讲座",
    contactSupport:"联系支持",
    userLogin:"用户登录",
    authorLogin:"作者登录",
    userCenter:"用户中心",
    logOut:"退出登录",
    knowMore:"了解更多",
    supportDesc:"想要更多了解或支持学会及其事工和项目，请致信 info@sfcpt.org",
    journalIntroduction:"期刊简介",
    latestJournal:"最新期刊",
    recommandRead:"推荐阅读",
    latestSpeech:"最新讲座",
    introduceRow1:"汉语公共神学学会（SOCIETY FOR CHINESE PUBLIC THEOLOGY）的使命是:发展本于圣经、普世且处境化的汉语公共神学体系，促进华人教会对公共事务的理解和健康参与，增进社会的整体福祉。",
    introduceRow2:"本会设立学术委员会与教会发展委员会，致力于公共神学及相关议题的学术研究，关注且推动公共神学在教会牧养、神学教育及宣教现场的开展与实践；通过出版期刊、丛书，组织课题研究，举办讲座和会议等形式持续深入推动汉语公共神学的建设和发展。",
    introduceRow3:"本会期刊《秉烛：汉语公共神学国际学刊》（INTERNATIONAL JOURNAL OFCHINESE PUBLIC THEOLOGY）创刊号于2024年12月出版。",
    introduceRow4:"本会于2024年7月22-26日，在美国密西根州的加尔文神学院举行主办了第一届汉语公共神学会议及成立会议，并推举何光沪、罗秉祥担任会长与副会长。",
    presidentIntroduction:"会长简介",
    inputEmail:"请输入邮箱账号",
    inputPassword:"请输入密码",
    validateCode:"验证码",
    presidents:[
        {
        name:"何光沪",
        pic: require("../../assets/images/firstPerson.png"),
        introduce:"原任中国社会科学院学术委员会委员、世界宗教研究所研究员、宗教学原理研究室主任，中国人民大学校务委员会委员、学位委员会委员、宗教学教授；著有《生于忧患、长于忧患：何光沪汉语神学文选》《Sino-Christian Theology》《秉烛隧中》 《百川归海：走向全球宗教哲学》《月映万川：宗教、社会与人生》《多元化的上帝观：20世纪西方宗教哲学概览》等。",
    },
    {
        name:"罗秉祥",
        pic: require("../../assets/images/FourthPerson.png"),
        introduce:"美国富勒神学院斯特芬伉俪中华研究讲席教授，中华研究中心学术主任。曾在香港浸会大学宗教及哲学系任教31年，及在洛杉矶正道神学院担任驻院学者及资深伦理学教授。在美国获得两个博士学位：耶鲁大学（1990，基督教伦理学），纽约州立大学水牛城分校（1982，西方道德哲学）。著有《黑白分明：基督教伦理学纵横谈》《自由社会的道德底线》《公理婆理话伦理》《同性恋的十字架：伦理学者的对话与交锋》。",
    }],
    userLoginTitle:"用户登录与注册",
    contactSupportRow1:"汉语公共神学学会目前正在美国注册登记成为非营利机构，",
    contactSupportRow2:"并期望可以取得501(c)(3)免税资格。",
    contactSupportRow3:"想要更多了解或支持学会及其事工和项目，请致信",
    committee:"学术委员会",
    forgetPassword:"忘记密码",
    search:"请输入内容",
    searchResult:"搜索结果",
}   