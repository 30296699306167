import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from '../src/api/request';
import ElementUI from 'element-ui';
import 'quill/dist/quill.core.css';
import 'element-ui/lib/theme-chalk/index.css';
import { i18n } from './i18n/index' //国际化

import message from './utils/message.js';
Vue.prototype.$messages = message;
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.use(ElementUI);
new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
