<!--
 * @description: 
 * @param: 
 * @return: 
 * @Author: zrh
 * @Date: 2024-01-01 09:34:06
 * @LastEditors: sueRimn
 * @LastEditTime: 2024-02-19 15:20:48
-->
<template>
  <div style="width: 100%" class="container">
    <div class="banner">
      <el-carousel height="500px" style="width: 100%">
        <el-carousel-item v-for="(item, index) in swiper" :key="index">
          <img :src="item.src" alt="" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- PC表示电脑端 MB表示手机端 -->
    <!-- 设置一个PC属性的class 设置一个MB的class  两个DOM结构 一个PC display:none 一个MB display:none-->
    <div
      class="latestMessage PC"
      @click="pushIntoDetail(messageLatest.id, 'message')"
    >
      <div class="leftBox">
        <div class="title">最新消息</div>
        <div class="summaryTitle">{{ messageLatest.name }}</div>
        <div class="createTime">{{ messageLatest.createTime }}</div>
        <div class="summary">{{ messageLatest.summary }}</div>
      </div>
      <div class="rightBox">
        <img :src="messageLatest.imgSrc" alt="" />
      </div>
    </div>
    <div
      class="latestMessage MB"
      @click="pushIntoDetail(messageLatest.id, 'message')"
    >
      <div class="title">最新消息</div>
      <div class="rightBox">
        <img :src="messageLatest.imgSrc" alt="" />
      </div>
      <div class="leftBox">
        <div class="summaryTitle">{{ messageLatest.name }}</div>
        <div class="createTime">{{ messageLatest.createTime }}</div>
        <div class="summary">{{ messageLatest.summary }}</div>
      </div>
    </div>
    <!-- 分割线 -->
    <!-- <div class="slice"></div> -->
    <div class="introduce">
      <div class="associationIntroduce" @click="pushIntoIntroduction">
        <h1>
          <!-- 学会简介 -->
          {{ $t("h.introduce") }}
        </h1>
        <img src="../assets/images/associationIntroduce.png" alt="" />
      </div>
      <div class="editorIntroduce" @click="pushIntoMember">
        <h1>
          <!-- 理事成员 -->
          {{ $t("h.member") }}
        </h1>
        <img src="../assets/images/editorIntroduce.png" alt="" />
      </div>
    </div>
    <!-- 文章模块 -->
    <div class="articles PC">
      <div
        class="article"
        v-for="(item, index) in article"
        :key="index"
        @click="pushIntoDetail(item.id, indexMeans[index])"
      >
        <div class="pic">
          <img :src="item.imgSrc" alt="" />
        </div>
        <div class="articleType">{{ item.type }}</div>
        <div class="articleTitle">{{ item.name }}</div>
        <div class="articleContent">{{ item.summary }}</div>
        <div class="toKnowMore">了解更多</div>
      </div>
    </div>
    <div class="articles MB">
      <div
        class="article"
        v-for="(item, index) in article"
        :key="index"
        @click="pushIntoDetail(item.id, indexMeans[index])"
      >
        <div class="pic">
          <img :src="item.imgSrc" alt="" />
        </div>
        <div class="articleType">{{ item.type }}</div>
        <div class="articleTitle">{{ item.name }}</div>
        <div class="articleContent">{{ item.summary }}</div>
        <div class="toKnowMore">
          <img src="../assets/images/toKnowMore.png" />
        </div>
      </div>
    </div>
    <!-- 会议讲座 -->
    <div class="forum PC" @click="pushIntoDetail(lectures.id, 'lecture')">
      <div class="forumLeftPart">
        <img :src="lectures.imgSrc" alt="" />
      </div>
      <div class="forumRightPart">
        <div class="title">{{ lectures.name }}</div>
        <div class="content">{{ lectures.summary }}</div>
        <div class="wantToKnowMore">了解更多</div>
      </div>
    </div>
    <!-- 会议讲座 -->
    <div class="forum MB" @click="pushIntoDetail(lectures.id, 'lecture')">
      <div class="title">{{ lectures.name }}</div>
      <div class="forumLeftPart">
        <img :src="lectures.imgSrc" alt="" />
      </div>
      <div class="forumRightPart">
        <div class="content">{{ lectures.summary }}</div>
      </div>
      <div class="toKnowMore">
        <img src="../assets/images/toKnowMore.png" alt="" />
      </div>
    </div>
    <!-- 支持--写死模块 -->
    <div class="accept">
      <div class="title">
        {{ $t("h.contactSupport") }}
      </div>
      <!-- 摘要 -->
      <div class="summary">
        {{ $t("h.supportDesc") }}
      </div>
      <!--  -->
      <div class="toKnowMore MB">
        <!-- <div class="wantToKnowMore" > 了解更多</div> -->
        <img
          src="../assets/images/toKnowMore.png"
          alt=""
          @click="pushIntoSupport"
        />
      </div>
      <div class="toKnowMore PC">
        <div class="wantToKnowMore" @click="pushIntoSupport">了解更多</div>
        <!-- <img src="../assets/images/toKnowMore.png" alt=""> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      swiper: [
        { src: require("../assets/images/banner1.jpg") },
        { src: require("../assets/images/banner2.jpg") },
        { src: require("../assets/images/banner3.jpg") },
      ],
      // 有且仅仅记录第一条
      messageLatest: {},
      article: [{}, {}, {}],
      lectures: {},
      indexMeans: ["journal", "book", "article"], //v-for循环 以index 作为类型的区分
    };
  },
  mounted() {
    this.getHomeData();
    // 在首页加载完成后 主动更新一下页面 确定登录用户的
    this.$emit("alreadyLogin");
  },
  methods: {
    // 跳转理事成员
    pushIntoMember() {
      this.$router.push("/editorialBoard");
    },
    // 跳转到学会简介
    pushIntoIntroduction() {
      this.$router.push("/introductionToTheSociety");
    },
    // 点击跳转到详情页
    pushIntoDetail(id, type) {
      this.$router.push({ path: "/messageDetail", query: { id, type } });
    },
    pushIntoSupport() {
      this.$router.push("/contactSupport");
    },
    // 获取首页数据
    async getHomeData() {
      this.getLatestMessage();
      this.getMagazineList();
      this.getBook();
      this.getArticle();
      this.getLectures();
    },
    // 获取最新消息
    async getLatestMessage() {
      const { data: res } = await this.$http.get("/busNews/list", {
        pageIndex: 1,
        pageSize: 10,
      });
      this.messageLatest = res.data.list[0];
      // 获取前端的IP地址
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      this.messageLatest.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        this.messageLatest.coverImage;
    },
    // 获取讲座信息
    async getLectures() {
      const { data: res } = await this.$http.get("/busLectures/list", {
        pageIndex: 1,
        pageSize: 10,
      });
      let newData = res.data.list[0];
      // 获取前端的IP地址
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      newData.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        newData.coverImage;
      newData.type = "期刊";
      this.lectures = newData;
    },
    // 获取期刊数据
    async getMagazineList() {
      const { data: res } = await this.$http.get("/busMagazine/list", {
        pageIndex: 1,
        pageSize: 10,
      });
      let newData = res.data.list[0];
      // 获取前端的IP地址
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      newData.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        newData.coverImage;
      newData.type = "期刊";
      this.$set(this.article, 0, newData);
    },
    // 获取丛书信息
    async getBook() {
      const { data: res } = await this.$http.get("/busBook/list", {
        pageIndex: 1,
        pageSize: 10,
      });
      let newData = res.data.list[0];
      // 获取前端的IP地址
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      newData.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        newData.coverImage;
      newData.type = "丛书";
      this.$set(this.article, 1, newData);
    },
    // 获取专项课题
    async getArticle() {
      const { data: res } = await this.$http.get("/busArticle/list", {
        pageIndex: 1,
        pageSize: 10,
      });
      let newData = res.data.list[0];
      // 获取前端的IP地址
      const frontendIP = window.location.hostname;
      // 获取前端的端口地址
      let frontendPort = window.location.port;
      if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
      newData.imgSrc =
        "http://" +
        frontendIP +
        ":" +
        frontendPort +
        process.env.VUE_APP_BASE_API +
        newData.coverImage;
      newData.type = "专项课题";
      this.$set(this.article, 2, newData);
    },
  },
};
</script>
<style lang="less" scoped>
@media (min-width: 768px) {
  * {
    margin: 0;
    padding: 0;
  }
  .container {
    overflow-x: hidden;
  }
  /* 在这里添加PC端的样式 */
  .banner {
    width: 100vw;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .MB {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
  }
  .latestMessage {
    width: 80vw;
    margin: 37px 10vw;
    display: flex;
    justify-content: flex-start;
    height: 400px;
    cursor: pointer;
    .title {
      color: #0f0f0f;
      font-size: 36px;
      font-weight: 600;
    }
    .leftBox {
      text-align: left;
      padding: 0 60px;
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 50%;
      background-color: #fafafa;

      .summaryTitle {
        margin-top: 10px;
        color: #0b2340;
        font-weight: 600;
        font-size: 26px;
      }
      .createTime {
        margin-top: 10px;
        color: #a9a9a9;
        font-weight: 600;
        font-size: 20px;
      }
      .summary {
        word-wrap: break-word;
        width: 100%;
        margin-top: 10px;
        color: #5b5b5b;
      }
    }
    .rightBox {
      margin-left: 120px;
      height: 400px;
      width: 500px;
      display: flex;

      // width: 50%;
      img {
        // max-width: 500px;
        // max-height: 400px;
        // min-height: 400px;
        // height: 400px;
        // width: 500px;
        height: 100%;
        width: 100%;
      }
    }
  }
  .slice {
    width: calc(100% - 4px);
    border: 2px dashed #909090;
    overflow: hidden;
  }
  .introduce {
    color: #000;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      margin: 0 100px;
    }
    img {
      width: 250px;
      height: 250px;
    }
    .associationIntroduce {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .editorIntroduce {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .articles {
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    padding: 40px 50px 20px 50px;
    .article {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 390px;
      cursor: pointer;
      padding-bottom: 40px;
      margin: 0 4.5vw;
      background-color: #fff;
      .pic {
        height: 250px;
        img {
          width: 390px;
          height: 250px;
          max-width: 390px;
          min-width: 370px;
          max-height: 250px;
        }
      }
      .articleType {
        box-sizing: border-box;
        display: flex;
        font-size: 22px;
        justify-content: flex-start;
        margin: 30px 0 0 20px;
        color: #a39f9f;
      }
      .articleTitle {
        display: flex;
        width: 80%;
        // height: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        justify-content: flex-start;
        text-align: left;
        font-weight: 600;
        font-size: 26px;
        margin: 10px 0 0 20px;
        color: #000;
      }
      .articleContent {
        // box-sizing: border-box;
        padding: 0 10px;
        font-size: 20px;
        // min-height: 5em;
        display: flex;
        text-align: left;
        color: #aeaeae;
        padding-top: 30px;
        margin: 0 10px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        justify-content: flex-start;
      }
      .toKnowMore {
        cursor: pointer;
        position: absolute;
        bottom: -30px;
        left: 30%;
        transform: translateX(-50%);
        cursor: pointer;
        width: 10vw;
        height: 30px;
        line-height: 30px;
        color: #912d39;
        font-weight: 600;
        border: 2px solid #9e4752;
        margin-left: 20%;
        margin-right: 5vw;
        margin-bottom: 40px;
      }
    }
  }
  .forum {
    height: 400px;
    display: flex;
    cursor: pointer;
    .forumLeftPart {
      width: 900px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 60%;
        height: 80%;
      }
    }
    .forumRightPart {
      flex: 1;
      display: flex;
      flex-direction: column;
      color: #c1c1c1;
      font-size: 1;
      // justify-content: center;
      align-items: center;
      .title {
        margin-top: 100px;
        font-size: 30px;
        font-weight: 600;
        color: #000000;
      }
      .content {
        height: 90px;
        overflow: hidden;
        margin-top: 20px;
        width: 300px;
      }
      .wantToKnowMore {
        cursor: pointer !important;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 50px;
        color: #871b29;
        font-size: 26px;
        font-weight: 600;
        border: 2px solid #851725;
      }
    }
  }
  .accept {
    height: 300px;
    width: 100vw;
    box-sizing: border-box;
    background: url("../assets/images/acceptBackground.png");
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: left;
    padding-top: 60px;
    padding-left: 200px;
    .title {
      font-size: 40px;
      color: #000000;
      font-weight: 600;
    }
    .wantToKnowMore {
      cursor: pointer !important;
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 50px;
      color: #871b29;
      font-size: 26px;
      font-weight: 600;
      border: 2px solid #851725;
    }
    .summary {
      margin-top: 10px;
      font-size: 18px;
      width: 500px;
    }
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
@media (max-width: 767px) {
  .container {
    width: 100vw !important;
    overflow: hidden;
  }
  .banner {
    // 不显示轮播图
    width: 0;
    height: 0;
    img {
      width: 100%;
    }
  }
  .PC {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
  .latestMessage {
    width: 84vw;
    overflow: hidden;
    margin: 37px 8vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 80vh;
    cursor: pointer;
    .title {
      width: 100%;
      color: #0f0f0f;
      letter-spacing: 3px;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 3vh;
    }
    .leftBox {
      text-align: left;
      padding: 0 0px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      background-color: #fafafa;

      .summaryTitle {
        margin-top: 10px;
        color: #0b2340;
        font-weight: 600;
        font-size: 26px;
      }
      .createTime {
        margin-top: 10px;
        color: #a9a9a9;
        font-weight: 600;
        font-size: 20px;
      }
      .summary {
        word-wrap: break-word;
        width: 100%;
        margin-top: 10px;
        color: #5b5b5b;
      }
    }
    .rightBox {
      height: 400px;
      width: 100%;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .slice {
    width: calc(100% - 4px);
    border: 2px dashed #909090;
    overflow: hidden;
  }
  .introduce {
    color: #000;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      // margin: 0 ;100px
      width: 60vw;
    }
    img {
      width: 50%;
      height: auto;
      // height: 50%;
    }
    .associationIntroduce {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
    .editorIntroduce {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .articles {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 8vw;
    padding-top: 5vh;
    padding-bottom: 10vh;
    // padding: 40px 100px 20px 100px;
    .article {
      display: flex;
      flex-direction: column;
      flex: 1;
      cursor: pointer;
      // margin: 0 8vw;
      background-color: #fff;
      margin-top: 30px;
      .pic {
        padding: 3vh 10vw 0 10vw;
        height: 200px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .articleType {
        box-sizing: border-box;
        display: flex;
        font-size: 22px;
        justify-content: flex-start;
        // margin: 30px 0 0 20px;
        color: #a39f9f;
        padding: 3vh 10vw 0 10vw;
      }
      .articleTitle {
        display: flex;
        width: 80%;
        height: auto;
        justify-content: flex-start;
        text-align: left;
        font-weight: 600;
        font-size: 26px;
        padding: 3vh 10vw 0 10vw;
        // margin: 10px 0 0 20px;
        color: #000;
      }
      .articleContent {
        font-size: 20px;
        // height: 10vh;
        display: flex;
        text-align: left;
        color: #aeaeae;
        padding-top: 20px;
        padding: 3vh 10vw 0 10vw;
        // margin: 0 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
      .toKnowMore {
        cursor: pointer;
        width: 100%;
        height: 50px;
        line-height: 30px;
        color: #912d39;
        text-align: right;
        img {
          margin-right: 8vw;
          margin-bottom: 4vh;
          width: 8vw;
          height: auto;
        }
      }
    }
  }
  .forum {
    height: 80vh;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .title {
      margin: 5vh 0;
      font-size: 30px;
      list-style: none;
      font-weight: 600;
      color: #000000;
    }
    .forumLeftPart {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 90%;
        height: auto;
      }
    }
    .forumRightPart {
      padding: 0 5vw;
      // flex: 1;
      display: flex;
      flex-direction: column;
      color: #c1c1c1;
      font-size: 1;
      align-items: center;

      .content {
        margin-top: 20px;
        width: 100%;
        // height: 10vh;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
    .toKnowMore {
      text-align: right;
      width: 80vw;
      padding: 0 10vw;
      height: 50px;
      color: #871b29;
      font-size: 26px;
      font-weight: 600;
    }
  }
  .accept {
    height: 40vh;
    box-sizing: border-box;
    background: url("../assets/images/acceptBackground.png");
    background-repeat: no-repeat;
    background-size: cover; /* 背景图片将会被拉伸以填充整个div */
    background-position: center; /* 图片在div中居中显示 */
    // text-align: left;
    // padding-top: 60px;
    // padding-left: 200px;
    .title {
      width: 100vw;
      padding-top: 5vh;
      font-size: 32px;
      color: #000000;
      font-weight: 600;
      margin-bottom: 40px;
    }
    .toKnowMore {
      margin-top: 50px;
      text-align: right;
      width: 90vw;
      padding-right: 10vw;
      height: 50px;
      color: #871b29;
    }
    .summary {
      margin-top: 10px;
      font-size: 18px;
      padding: 0 10vw;
      width: 80vw;
      text-align: left;
    }
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>
